import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listWorkDiaries,
  listScheduledWorkDiaries,
  listPatientOrders,
  createWorkDiary,
  deleteWorkDiary,
  addBranchAsset,
  generateBranchUploadURL,
  deleteBranchAsset,
  upload,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";
import moment from "moment";

export const fetchPage = createAsyncThunk(
  "workDiaries/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listWorkDiaries(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load work diaries", message: error })
      );
    }
    return data;
  }
);

export const fetchScheduledWorkDiaries = createAsyncThunk(
  "workDiaries/loadSscheduling",
  async (listParams, thunkAPI) => {
    const { error, data } = await listScheduledWorkDiaries(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load scheduling", message: error })
      );
    }
    return data;
  }
);

export const fetchOrders = createAsyncThunk(
  "workDiaries/loadOrders",
  async (listParams, thunkAPI) => {
    const { error, data } = await listPatientOrders(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load orders", message: error }));
    }
    return data;
  }
);

export const createRecord = createAsyncThunk(
  "workDiaries/create",
  withNotifications(
    createWorkDiary,
    "create_work_diary",
    "create_work_diary_success",
    "create_work_diary_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "workDiaries/delete",
  withNotifications(
    deleteWorkDiary,
    "delete_work_diary",
    "delete_work_diary_success",
    "delete_work_diary_error"
  )
);

export const addAsset = createAsyncThunk(
  "workDiaries/addAsset",
  withNotifications(
    async ({ branchId, assetName, file }) => {
      const {
        data: { url },
      } = await generateBranchUploadURL(branchId, assetName, file);
      await upload(url, file);
      return addBranchAsset(branchId, assetName, url);
    },
    "add_wotk_diary_asset",
    null,
    "add_wotk_diary_asset_error"
  )
);

export const deleteAsset = createAsyncThunk(
  "workDiaries/deleteAsset",
  withNotifications(
    deleteBranchAsset,
    "delete_wotk_diary_asset",
    null,
    "delete_wotk_diary_asset_error"
  )
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
  },
  scheduledWorkDiariesPage: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
  },
  activeYear: moment().format("YYYY"),
  activeMonth: moment().format("M"),
  activeDiary: {},
};

const workDiariesSlice = createSlice({
  name: "workDiaries",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setScheduledWorkDiariesPage(state, { payload: page }) {
      state.scheduledWorkDiariesPage = page;
    },
    setOrdersPage(state, { payload: page }) {
      state.ordersPage = page;
    },
    setYear(state, action) {
      const { year } = action.payload;
      state.activeYear = year;
    },
    setMonth(state, action) {
      const { month } = action.payload;
      state.activeMonth = month;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeYear = initialState.activeYear;
      state.activeMonth = initialState.activeMonth;
      state.activeDiary = initialState.activeDiary;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchScheduledWorkDiaries.fulfilled]: (state, action) => {
      state.scheduledWorkDiariesPage = {
        ...state.scheduledWorkDiariesPage,
        ...action.payload,
      };
    },
    [fetchScheduledWorkDiaries.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.ordersPage = { ...state.ordersPage, ...action.payload };
    },
    [fetchOrders.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [addAsset.fulfilled]: (state, { payload: { error, data } }) => {
      // state.activeDiary.file_id = data.id;
      state.error = error;
    },
    [addAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  setPage,
  setScheduledWorkDiariesPage,
  setOrdersPage,
  setYear,
  setMonth,
  reset,
} = workDiariesSlice.actions;

export default workDiariesSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().workDiaries.page,
    ...params,
    filter: {
      $and: [
        { year: { $eq: +getState().workDiaries.activeYear } },
        { month: { $eq: +getState().workDiaries.activeMonth } },
      ],
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  const patientId = getState().patientDetails.activePatient.id;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, patientId }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().workDiaries.page));
};

export const loadScheduledWorkDiaries = (params) => (dispatch, getState) => {
  const currentMonth = moment();
  currentMonth.set("year", +getState().workDiaries.activeYear);
  currentMonth.set("month", +getState().workDiaries.activeMonth - 1);
  const from = moment(currentMonth).startOf("month");
  const to = moment(currentMonth).endOf("month");

  const newState = {
    ...getState().workDiaries.scheduledWorkDiariesPage,
    ...params,
    query: {
      from: moment.utc(from).format(),
      to: moment.utc(to).format(),
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  const patientId = getState().patientDetails.activePatient.id;
  const branchId = getState().patientDetails.activePatient.branch;
  if (patientId && branchId) {
    dispatch(setScheduledWorkDiariesPage(newState));
    dispatch(
      fetchScheduledWorkDiaries({
        ...newState,
        scopePrefix,
        patientId,
        branchId,
      })
    );
  }
};
