import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createPatientHospitalization,
  updatePatientHospitalization,
  getPatientHospitalizations,
  deletePatientHospitalization,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeHospitalization: {},
  hospitalizations: [],
};

export const loadHospitalizations = createAsyncThunk(
  "patientHospitalizations/load",
  async (patientId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getPatientHospitalizations({ patientId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get Hospitalization", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createHospitalization = createAsyncThunk(
  "patientHospitalizations/create",
  withNotifications(
    createPatientHospitalization,
    "create_patient_hospitalization",
    "create_patient_hospitalization_success",
    "create_patient_hospitalization_error"
  )
);

export const updateHospitalization = createAsyncThunk(
  "patientHospitalizations/update",
  withNotifications(
    updatePatientHospitalization,
    "update_patient_hospitalization",
    "update_patient_hospitalization_success",
    "update_patient_hospitalization_error"
  )
);

export const deleteHospitalization = createAsyncThunk(
  "patientAbsences/delete",
  withNotifications(
    deletePatientHospitalization,
    "delete_patient_hospitalization",
    "delete_patient_hospitalization_success",
    "delete_patient_hospitalization_error"
  )
);

const patientHospitalizationSlice = createSlice({
  name: "patientHospitalizations",
  initialState: initialState,
  extraReducers: {
    [loadHospitalizations.fulfilled]: (state, action) => {
      state.hospitalizations = action.payload.items;
    },
    [loadHospitalizations.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default patientHospitalizationSlice.reducer;
