import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createHouseVisit,
  updateHouseVisit,
  getHouseVisit,
  deleteHouseVisit,
  listPatientOrders,
} from "../../api/server";
import { unflatten } from "flat";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeHouseVisit: {},
  changedItems: {
    changed: [],
    values: {},
  },
};

export const loadRecord = createAsyncThunk(
  "houseVisit/load",
  async (params, thunkAPI) => {
    // Combine calls to HTTP endpoints here, orders and house visits.

    const orderParams = { ...params };
    const { error, data } = await listPatientOrders(orderParams);
    if (error) {
      return async () =>
        thunkAPI.dispatch(notifyError({ title: "get orders", message: error }));
    }

    // Cheap shortcut...filter orders out that have null effective_to date
    // or effective_to date before today until we figure out server-side
    // filtering.
    const orderData = data;
    orderData.items = orderData.items.filter(item => {
      if (item.effective_to === null) {
        return true;
      }
      return new Date(item.effective_to) >= new Date();
    });

    return getHouseVisit(params).then(({ error, data }) => {
      if (error) {
        thunkAPI.dispatch(
          notifyError({ title: "Get house visit", message: error }),
        );
      }

      // Mutate the data returned from house visits to include orders.
      data.orders = orderData.items;
      return data;
    });
  },
);

export const commitChanges = createAsyncThunk(
  "houseVisit/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { houseVisit } = thunkAPI.getState();
      const { activeHouseVisit } = houseVisit;
      const activePatient = activeHouseVisit.patient;
      const { branch: branchId, id } = activePatient;
      const {
        changedItems: { changed, values },
      } = houseVisit;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return updateHouseVisit({
        house_visit: { id: activeHouseVisit.id, ...unflatten(toUpdate) },
        patientId: id,
        branchId,
      });
    },
    "save_house_visit",
    "save_house_visit_success",
    "save_house_visit_error",
  ),
);

export const createRecord = createAsyncThunk(
  "houseVisit/create",
  withNotifications(
    async (params, thunkAPI) => {
      const { patientDetails, houseVisit } = thunkAPI.getState();
      const { activePatient } = patientDetails;
      const { branch: branchId, id } = activePatient;
      const {
        changedItems: { changed, values },
      } = houseVisit;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return createHouseVisit({
        house_visit: { ...unflatten(toUpdate) },
        patientId: id,
        branchId,
      });
    },
    "create_house_visit",
    "create_house_visit_success",
    "create_house_visit_error",
  ),
);

export const deleteRecord = createAsyncThunk(
  "houseVisit/delete",
  withNotifications(
    deleteHouseVisit,
    "delete_house_visit",
    "delete_house_visit_success",
    "delete_house_visit_error",
  ),
);

const houseVisitSlice = createSlice({
  name: "houseVisit",
  initialState: initialState,
  reducers: {
    setActiveHouseVisit(state, action) {
      const { record } = action.payload;
      state.activeHouseVisit = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;

      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeHouseVisit = {};
      state.changedItems = initialState.changedItems;
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeHouseVisit = action.payload;
    },
    [loadRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeHouseVisit = data || {};
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [commitChanges.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeHouseVisit = data || {};
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeHouseVisit = {};
    },
    [deleteRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const { reset, setActiveHouseVisit, setChangedFormItems } =
  houseVisitSlice.actions;

export default houseVisitSlice.reducer;
