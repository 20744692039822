import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listEmployees } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "staff/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployees(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load staff", message: error }));
    }
    return data;
  }
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};
const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
      $and: [{ staff_member: true }, { $or: [{ status: 1 }, { status: 3 }] }],
    },
    finalfilters: {
      status: [],
      gender_id: [],
      branches: [],
    },
    order: {
      field: "surename",
      order: "descend",
    },
    items: [],
  },
  templates: {
    items: [],
  },
};

const staffSlice = createSlice({
  name: "staff",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },

    "employeeDetails/activate/fulfilled": onItemChanged,
    "employeeDetails/deactivate/fulfilled": onItemChanged,
    "employeeDetails/update/fulfilled": onItemChanged,
    "employeeDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setPage, reset } = staffSlice.actions;

export default staffSlice.reducer;

export const loadPage = (params, query) => (dispatch, getState) => {
  const { finalfilters, order } = params;
  let newOrder = order;
  if (!order.order) {
    newOrder = initialState.page.order;
  }

  let filter = {
    freeText: params.filter.freeText,
  };

  filter["$and"] =
    params && params.filter["$and"]
      ? [
          ...params.filter["$and"].filter((i) => i.staff_member !== true),
          { staff_member: true },
        ]
      : [{ staff_member: true }];
  let newParams = { ...params, order: newOrder, filter };

  if (finalfilters && !finalfilters.status) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: [{ $and: initialState.page.filter["$and"] }, ...otherFilters],
      freeText: params.filter.freeText,
    };
  }
  const newState = {
    ...getState().staff.page,
    ...newParams,
  };
  const scopePrefix = getState().user.scopePrefix;
  const newQuery = query ? query : { branches: newState.finalfilters.branches };
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, query: newQuery }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().staff.page));
};
