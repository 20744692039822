import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateUser,
  deleteUser,
  getRecord,
  activateUser as activateAPI,
  deactivateUser as deactivateAPI,
} from "../../api/server";
import NotificationError from "../notifications/error";
import {
  notifySuccess,
  withNotifications,
} from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeUser: {},
};

export const loadRecord = createAsyncThunk(
  "userDetails/loadUser",
  async (id, thunkAPI) => {
    return getRecord("users", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const updateRecord = createAsyncThunk(
  "userDetails/update",
  withNotifications(
    updateUser,
    "update_user",
    "update_user_success",
    "update_user_error"
  )
);

export const activateUser = createAsyncThunk(
  "userDetails/activate",
  withNotifications(
    activateAPI,
    "activate_user",
    "activate_user_success",
    "activate_user_error"
  )
);

export const deactivateUser = createAsyncThunk(
  "userDetails/deactivate",
  withNotifications(
    deactivateAPI,
    "deactivate_user",
    "deactivate_user_success",
    "deactivate_user_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "userDetails/delete",
  withNotifications(
    deleteUser,
    "delete_user",
    "delete_user_success",
    "delete_user_error"
  )
);

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: initialState,
  reducers: {
    setActiveUser(state, action) {
      const { record } = action.payload;
      state.activeUser = record;
    },
    reset(state, action) {
      state.error = null;
      state.activeUser = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeUser = action.payload;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeUser = data;
      state.error = error;
    },
    [activateUser.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeUser = data;
      state.error = error;
    },
    [deactivateUser.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeUser = data;
      state.error = error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeUser = {};
    },
  },
});

export const { setActiveUser, reset } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
