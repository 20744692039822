import { createAsyncThunk, createSlice,SerializedError } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import moment from "moment";
import { listPatients, getSettingsData,listSubProjects } from "../../api/server";
import { notifyError,withNotifications } from "../notifications/notificationsSlice";
import {updateSubPatientsStaffWorkerInCharge} from "../../api/server"

export const fetchPage = createAsyncThunk<{
    items:[],state:RootState
},any>(
    "employeeSubPatients/loadPage",
    async (listParams,thunkAPI)=>{
        const {error,data} = await listPatients(listParams);
        if (error) {
            thunkAPI.dispatch(
                notifyError({title:"Load Sub Paitients", message:error})
            );
        }
        return data;
    }
)

// asybc thunk for commit changes calling updateSubPatientsStaffWorkerInCharge with notification on screen with result
export const commitChanges = createAsyncThunk(
  'employeeSubPathients/updateStaffWorkerInCharge',
  withNotifications(
    async (params:any,thunkAPI:any)=>{
      return updateSubPatientsStaffWorkerInCharge(params)

    },
    "update_staff_worker",
    "update_staff_worker_success",
    "update_staff_worker_error",
  )
);

// service areas for filter
export const getServiceAreas = createAsyncThunk(
  'employeeSubPatients/getServiceAreas',
    async (params:any,thunkAPI:any)=>{
      params = { name:"system_tables/service_areas" }
      const {error,data} = await getSettingsData(params)
      if (error) {
        thunkAPI.dispatch(
            notifyError({title:"Load Service Areas", message:error})
        );
      }
      return data;
    }
)

// branch extentions for filter
export const getBrenchExtensions = createAsyncThunk(
  'employeeSubPatients/getBrenchExtensions',
    async (params:any,thunkAPI:any)=>{
      params = { name:"system_tables/branch_extensions" }
      const {error,data} = await getSettingsData(params)
      if (error) {
        thunkAPI.dispatch(
            notifyError({title:"Load Branch Extensions", message:error})
        );
      }
      return data;
    }
)

// sub projects for filter
export const getSubProjects = createAsyncThunk(
    "employeeSubPatients/getSubProjects",
    async (params:any,thunkAPI:any)=>{
      const {error,data} = await listSubProjects(params)
      if (error) {
        thunkAPI.dispatch(
            notifyError({title:"Load Sub Projects", message:error})
        );
      }
      return data;
    }
)

interface page { current:number,pageSize:number,filter:any, finalfilters:any,order:any,items:any[],fields:string,total:number,employeeId:number}
interface stateSlice {page:page,error:null|SerializedError,serviceAreas:any,subProjects:any,branchExtensions:any}
const initialPageSize=10;
const initialState:stateSlice = {
    page: {
      current: 1,
      pageSize: initialPageSize,
      filter: {
        freeText: null,
        $or: [
          { status_id: 1 },
          { status_id: 3 },
          { status_id: 4 },
          {
            passing_date: {
              $gt: moment().subtract(1, "month").startOf("month").format(),
            },
          },
        ],
      },
      order: {
        field: "surename",
        order: "ascend",
      },
      items: [],
      finalfilters: {
        status_id: [],
        gender_id: [],
      },
      fields:
        "first_name,surename,status_id,local_id_number,contact_info,birthday,gender_id,address,avatar_url,id,branch_id,branch,service_area_id,staff_worker_in_charge_id,subproject_id,branch_extension_id",
        total:0,  
        employeeId:0,
    },
    error:null,
    serviceAreas:null,
    subProjects:null,
    branchExtensions:null,
  };

  const subPatientsSlice = createSlice({
    name:"employeeSubPatients",
    initialState:initialState,
    reducers:{
        setPage(state,{payload:page}) {
            state.page = page;
        },
        reset(state,action) {
            state.page= initialState.page;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchPage.fulfilled,(state,action)=>{
            state.page = {...state.page,...action.payload}
        });

        builder.addCase(fetchPage.rejected,(state,action)=>{
            state.error = action.error;
        })
        builder.addCase(getServiceAreas.fulfilled,(state,action)=>{
          state.serviceAreas = action.payload;
        })
        builder.addCase(getServiceAreas.rejected,(state,action)=>{
          state.error = action.error;
        })
        builder.addCase(getBrenchExtensions.fulfilled,(state,action)=>{
            state.branchExtensions = action.payload;
        })
          builder.addCase(getBrenchExtensions.rejected,(state,action)=>{
            state.error = action.error;
        })
        builder.addCase(getSubProjects.fulfilled,(state,action)=>{
          state.subProjects = action.payload;
        })
        builder.addCase(getSubProjects.rejected,(state,action)=>{
          state.error = action.error;
        })
    }
  })

  export const { setPage,reset} = subPatientsSlice.actions;

  export const loadPage = (params:any,query:any,employeeId:any) => (dispatch:any, getState:any)=>{
    const {finalfilters,order} = params;
    let newOrder = order;
    if (!order.order) {
      newOrder = initialState.page.order;
    }

    let filter:any = {
        freeText: params.filter.freeText,
      };
    
      let temp:any;
      let without:any;
     
      if(params && params.filter["$and"]){
         without = params.filter["$and"].filter((i:any) => !i.staff_worker_in_charge_id);
         temp = [
             ...without,
             { staff_worker_in_charge_id: employeeId },
           ]
     } else {
   
     temp= [{ staff_worker_in_charge_id: employeeId }];
     }
     
     filter["$and"] = temp
     
     let newParams = { ...params, order: newOrder, filter , fields:initialState.page.fields};
   
     if (finalfilters && !finalfilters.status) {
       const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
       newParams.filter = {
         $and: [ ...otherFilters ,{ staff_worker_in_charge_id: employeeId }],
         freeText: params.filter.freeText,
       };
      }
     
    
    const newState = {
      ...getState().employeeSubPatients.page,
      ...newParams,
      employeeId,
    };
    const scopePrefix = getState().user.scopePrefix;
    dispatch(setPage(newState));
    dispatch(fetchPage({ ...newState, scopePrefix }));

  }

export default subPatientsSlice.reducer;
