import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listEmployeeHouseVisits,
  employeeHouseVisitsUpdate,
  employeeHouseVisitsTransfer,
  verifyControllersHouseVisits,
  listControllersHouseVisits,
  getSettingsData
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";
import moment from "moment";

const fetchPage = createAsyncThunk(
  "employeeHouseVisits/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployeeHouseVisits(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load employeeHouseVisits", message: error })
      );
    }
    return data;
  }
);

export const fetchControllersPage = createAsyncThunk(
  "employeeHouseVisits/loadControllersPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listControllersHouseVisits(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load controllersHouseVisits", message: error })
      );
    }
    return data;
  }
);

// service areas for filter
export const getServiceAreas = createAsyncThunk(
  'employeeHouseVisits/getServiceAreas',
    async (params,thunkAPI)=>{
      params = { name:"system_tables/service_areas" }
      const {error,data} = await getSettingsData(params)
      if (error) {
        thunkAPI.dispatch(
            notifyError({title:"Load Service Areas", message:error})
        );
      }
      return data;
    }
)

export const updateHouseVisits = createAsyncThunk(
  "employeeHouseVisits/update",
  withNotifications(
    employeeHouseVisitsUpdate,
    "update_employee_house_visits",
    "update_employee_house_visits_success",
    "update_employee_house_visits_error"
  )
);

export const verifyControllersVisits = createAsyncThunk(
  "employeeHouseVisits/verifyControllersHouseVisits",
  withNotifications(
    verifyControllersHouseVisits,
    "verify_controllers_house_visits",
    "verify_controllers_house_visits_success",
    "verify_controllers_house_visits_error"
  )
);

export const transferHouseVisits = createAsyncThunk(
  "employeeHouseVisits/update",
  withNotifications(
    employeeHouseVisitsTransfer,
    "transfer_employee_house_visits",
    "transfer_employee_house_visits_success",
    "transfer_employee_house_visits_error"
  )
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};

const initialPageSize = 0;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "date",
      order: "ascend",
    },
    items: [],
    total:0,
  },
  controllersPage: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "date",
      order: "ascend",
    },
    items: [],
    total:0,
  },
  activeMonth: moment().format(),
  serviceAreas:null,
  moveView:false,
  datesRange:null,
};

const employeeHouseVisitsSlice = createSlice({
  name: "employeeHouseVisits",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setControllersPage(state, { payload: page }) {
      state.controllersPage = page;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.settings.changedItems.changed = changed;
      state.settings.changedItems.values = values;
    },
    setMonth(state, action) {
      state.activeMonth = action.payload;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeMonth = moment().format();
      state.controllersPage = initialState.page;
    },
    setMoveView(state,action) {
      state.moveView = action.payload;
      if(action.payload===true) {
        state.page.pageSize = 10;
      } else {
        state.page.pageSize = initialPageSize;
      }
    },
    setDatesRange(state,action) {
      state.datesRange = action.payload;
    }
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchControllersPage.fulfilled]: (state, action) => {
      state.controllersPage = { ...state.controllersPage, ...action.payload };
    },
    [fetchControllersPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    "houseVisit/update/fulfilled": onItemChanged,
    "houseVisit/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
   [getServiceAreas.fulfilled]:(state,action)=>{
      state.serviceAreas = action.payload;
    },
    [getServiceAreas.rejected]:(state,action)=>{
      state.error = action.error;
    },
  },
});

export const {
  setPage,
  setControllersPage,
  setChangedFormItems,
  setMonth,
  reset,
  setMoveView,
  setDatesRange
} = employeeHouseVisitsSlice.actions;

export default employeeHouseVisitsSlice.reducer;

export const loadPage = (params,queryParams) => (dispatch, getState) => {
  let from = moment(getState().employeeHouseVisits.activeMonth)
    .startOf("month")
    .format();
  let to = moment(getState().employeeHouseVisits.activeMonth)
    .endOf("month")
    .format();
  let moveView = getState().employeeHouseVisits.moveView;
  if (moveView) {
    if (!!(getState().employeeHouseVisits.datesRange)){
      from = getState().employeeHouseVisits.datesRange.from;
      to = getState().employeeHouseVisits.datesRange.to;
    } else {
      to=null;
    }
  }

  
  const newState = {
    ...getState().employeeHouseVisits.page,
    ...params,
    query: {
      from,
      to,
      moveView,
      ...queryParams
    },
  };

  if(newState.order.field==="address") {
    newState.order.field ="patient.address_street";
  }

  const scopePrefix = getState().user.scopePrefix;
  const employeeId = getState().employeeDetails.activeEmployee.id;

  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, employeeId }));
};

export const loadControllersPage = (params) => (dispatch, getState) => {
  let from = moment(getState().employeeHouseVisits.activeMonth)
    .startOf("month")
    .format();
  let to = moment(getState().employeeHouseVisits.activeMonth)
    .endOf("month")
    .format();
  const newState = {
    ...getState().employeeHouseVisits.controllersPage,
    ...params,
    query: {
      from,
      to,
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  const employeeId = getState().employeeDetails.activeEmployee.id;

  dispatch(setControllersPage(newState));
  dispatch(fetchControllersPage({ ...newState, scopePrefix, employeeId }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().employeeHouseVisits.page));
};
