
const thunkSuffixes = ["pending", "fulfilled", "rejected"]

const pendingReducer = (state = {}, action) => {
  let { type } = action;
  const typeParts = type.split("/");
  const thunkType = typeParts.pop();
  const actionType = typeParts.join("/");
  if (thunkSuffixes.indexOf(thunkType) >= 0) {
    if (thunkType === "pending") {
     return {...state, [actionType]: true}
    }
    const {[actionType]: toRemove, ...other } = state;
    return other;

  }
  return state;
}

export default pendingReducer