import sanitize from "sanitize-filename";

/**
 * Returns a string meant as a file name which is a combination of
 * the entity's (patient or worker) first and last name, and the description
 * the user entered when uploading the file.
 * @param {Object} state redux state.
 * @param {String} fileDesc The user input of the file description from the upload modal. 
 * @param {String} filename The original filename (from which to extract the original file extension)
 * @returns {String | null} The normalized filename, or null if active patient/employee are empty.
 */
export function normalizeFileNameFromState(state: any, fileDesc: string, filename: string): string|null {
    // First, get the last and first name from state.
    let surname, firstName;
    if (state.patientDetails.activePatient.surename) {
      surname = state.patientDetails.activePatient.surename;
      firstName = state.patientDetails.activePatient.first_name;
    } else if (state.employeeDetails.activeEmployee.surename) {
      surname = state.employeeDetails.activeEmployee.surename;
      firstName = state.employeeDetails.activeEmployee.first_name;
    } else {
      console.warn("couldn't get entity details when mutating document filename");
      return null;
    }
  
    // Now we should have a first and last name, combine it with the description typed by user
    // and ensure the resulting string is safe as a file name.
    const ext = filename.split('.').pop();
    fileDesc = fileDesc
      .trim()
      .replace(/\s+/g, "-")  // replace internal spaces with "-"
      .replace(/&/g, ""); // ampersand is allowed in filename but not recommended.
  
    return sanitize(`${firstName}-${surname}-${fileDesc}.${ext}`);
  }