import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getWorkPlan,
  simulateWorkPlan,
  updateWorkPlan,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const loadPlan = createAsyncThunk(
  "plan/loadPlan",
  async (params, thunkAPI) => {
    return getWorkPlan(params).then(({ error, data }) => {
      if (error) {
        thunkAPI.dispatch(notifyError({ title: "Get Plan", message: error }));
      }
      return data;
    });
  }
);

export const updatePlan = createAsyncThunk(
  "plan/update",
  withNotifications(
    updateWorkPlan,
    "update_plan",
    "update_plan_success",
    "update_plan_error"
  )
);

export const simulatePlan = createAsyncThunk(
  "plan/simulate",
  withNotifications(
    async (params, thunkAPI) => {
      try {
        const { data } = await simulateWorkPlan(params);
        return data;
      } catch (err) {
        throw err;
      }
    },
    "simulate_plan",
    null,
    "simulate_plan_error"
  )
);

const initialState = {
  activeEvent: null,
  mode: null,
  activePlan: {
    recurrent_events: [],
  },
  serverPlan: {
    recurrent_events: [],
  },
  activeSimulatedPlan: {
    recurrent_events: [],
  },
};

const planSlice = createSlice({
  name: "plan",
  initialState: initialState,
  reducers: {
    setActivePlan(state, action) {
      state.activePlan = action.payload;
    },
    setServerPlan(state, action) {
      state.serverPlan = action.payload;
    },
    setActiveEvent(state, action) {
      state.activeEvent = action.payload;
      if (
        action.payload &&
        action.payload.duplicateWeekdays &&
        action.payload.duplicateWeekdays.length > 0 &&
        state.mode === "new"
      ) {
        let duplicatedEvents = [];
        action.payload.duplicateWeekdays.forEach((item, i) => {
          if (item !== action.payload.weekday) {
            const temp = {
              ...action.payload,
              id: action.payload.id + 1 + item,
              duplicated: true,
              weekday: item,
            };
            duplicatedEvents.push(temp);
          }
        });
        const filtered = state.activePlan.recurrent_events.filter(
          (i) => i.duplicated !== true
        );
        state.activePlan = {
          ...state.activePlan,
          recurrent_events: [...filtered, ...duplicatedEvents],
        };
      }
    },
    changeEmployee(state, action) {
      const { employee, eventId } = action.payload;
      const filtered = state.activePlan.recurrent_events.filter(
        (i) => i.id !== eventId
      );
      const selectedEvent = state.activePlan.recurrent_events.filter(
        (i) => i.id === eventId
      )[0];
      selectedEvent["employee"] = employee;
      selectedEvent["employee_id"] = employee.id;
      selectedEvent["employee.tariff_id"] = employee.tariff_id;
      state.activePlan = {
        ...state.activePlan,
        recurrent_events: [...filtered, selectedEvent],
      };
    },
    setModalMode(state, action) {
      state.mode = action.payload;
    },
    reset(state, action) {
      state.activeEvent = null;
      state.activePlan = {
        recurrent_events: [],
      };
      state.serverPlan = {
        recurrent_events: [],
      };
      state.activeSimulatedPlan = {};
    },
  },
  extraReducers: {
    [loadPlan.fulfilled]: (state, { payload }) => {
      state.activePlan = { ...payload };
      state.serverPlan = { ...payload };
    },
    [loadPlan.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updatePlan.fulfilled]: (state, { payload }) => {
      state.activePlan = { ...payload.data };
      state.serverPlan = { ...payload.data };
    },
    [updatePlan.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [simulatePlan.fulfilled]: (state, { payload }) => {
      state.activeSimulatedPlan = payload;
    },
    [simulatePlan.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const {
  setActiveEvent,
  setActivePlan,
  setServerPlan,
  setModalMode,
  changeEmployee,
  reset,
} = planSlice.actions;

export default planSlice.reducer;
