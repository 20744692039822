import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unflatten } from "flat";
import {
  createClient,
  updateClient,
  deleteClient,
  createPrivateClient,
  getRecord,
  activateClient,
  deactivateClient,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeClient: {},
  activeTemplate: null,
  changedItems: {
    changed: [],
    values: [],
  },
};

export const loadRecord = createAsyncThunk(
  "clientDetails/loadClient",
  async (id, thunkAPI) => {
    const scopePrefix = thunkAPI.getState().user.scopePrefix;
    return getRecord("clients", id, scopePrefix).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const createRecord = createAsyncThunk(
  "clientDetails/create",
  withNotifications(
    createClient,
    "create_client",
    "create_client_success",
    "create_client_error"
  )
);

export const commitChanges = createAsyncThunk(
  "clientDetails/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { clientDetails } = thunkAPI.getState();
      const { activeClient } = clientDetails;
      const { id } = activeClient;
      const {
        changedItems: { changed, values },
      } = clientDetails;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});

      return updateClient({
        id,
        ...unflatten(toUpdate),
      });
    },
    "save_client",
    "save_client_success",
    "save_client_error"
  )
);

export const saveRecord = createAsyncThunk(
  "clientDetails/update",
  withNotifications(
    updateClient,
    "save_client",
    "save_client_success",
    "save_client_error"
  )
);

export const createPrivateRecord = createAsyncThunk(
  "clientDetails/createPrivate",
  withNotifications(
    createPrivateClient,
    "save_private_client",
    "save_private_client_success",
    "save_private_client_error"
  )
);

export const activateRecord = createAsyncThunk(
  "clientDetails/activate",
  withNotifications(
    activateClient,
    "activate_client",
    "activate_client_success",
    "activate_client_error"
  )
);

export const deactivateRecord = createAsyncThunk(
  "clientDetails/deactivate",
  withNotifications(
    deactivateClient,
    "deactivate_client",
    "deactivate_client_success",
    "deactivate_client_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "clientDetails/delete",
  withNotifications(
    deleteClient,
    "delete_client",
    "delete_client_success",
    "delete_client_error"
  )
);

const clientDetailsSlice = createSlice({
  name: "clientDetails",
  initialState: initialState,
  reducers: {
    setActiveClient(state, action) {
      const { record } = action.payload;
      state.activeClient = record;
    },
    setActiveTemplate(state, action) {
      const {
        record: { id, ...template },
      } = action.payload;
      state.activeTemplate = id ? template : null;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeClient = {};
      state.activeTemplate = null;
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeClient = action.payload;
    },
    [loadRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeClient = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeClient = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
    [commitChanges.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createPrivateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeClient = data;
      state.error = error;
    },
    [createPrivateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [activateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeClient = data;
      state.error = error;
    },
    [activateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deactivateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeClient = data;
      state.error = error;
    },
    [deactivateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeClient = {};
    },
    [deleteRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  reset,
  setActiveClient,
  setActiveTemplate,
  setChangedFormItems,
} = clientDetailsSlice.actions;

export const isClientActive = (record, branchID) => {
  if (!record.branch_active || !record.active) {
    return false;
  }
  if (!branchID) {
    return true;
  }
  return (
    record.branch_active.find((ba) => ba.branch_id === branchID && ba.active) !=
    null
  );
};

export default clientDetailsSlice.reducer;
