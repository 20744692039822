import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  syncOk2go,
  resetOk2go,
  exportRevaha as exportRevahaApi,
  uploadRevahaSchedules,
  fetchJobStatus,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import NotificationError from "../../features/notifications/error";

import moment from "moment";
export const syncOk2Go = createAsyncThunk(
  "ok2Go/sync",
  withNotifications(
    syncOk2go,
    "ok2Go_sync",
    "ok2Go_sync_success",
    "ok2Go_sync_error"
  )
);

export const resetOk2Go = createAsyncThunk(
  "ok2Go/reset",
  withNotifications(
    resetOk2go,
    "ok2Go_reset",
    "ok2Go_reset_success",
    "ok2Go_reset_error"
  )
);

export const syncOk2GoRow = createAsyncThunk(
  "ok2Go/sync_row",
  withNotifications(
    syncOk2go,
    "ok2Go_sync_row",
    "ok2Go_sync_row_success",
    "ok2Go_sync_row_error"
  )
);

export const exportRevaha = createAsyncThunk(
  "employees/export",
  withNotifications(
    exportRevahaApi,
    "export_employees",
    "export_employees_success",
    "export_employees_error"
  )
);
export const uploadRevahaSchedulesFile = createAsyncThunk(
  "ok2Go/upload_revaha_schedules_file",
  withNotifications(
    uploadRevahaSchedules,
    "upload_revaha_schedules_file",
    "upload_revaha_schedules_file_success",
    "upload_revaha_schedules_file_error"
  )
);

export const getJobStatus = createAsyncThunk(
  "ok2Go/get_job_status",
  async (params) => {
    try {
      const action = await fetchJobStatus(params);
      return action;
    } catch (error) {
      throw new NotificationError("get_job_status_error", error.error);
    }
  }
);

const initialPageSize = 10;
const initialState = {
  api: null,
  activeBranch: null,
  activeSection: null,
  activeEntity: null,
  activeRowId: null,
  activeDate: moment().format(),
  staffId: null,
  clientId: null,
  jobId: null,
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
      // $and: [],
    },
    order: {
      // field: "surename",
      // order: "descend",
    },
  },
  finalfilters: {
    has_event_status_errors: [],
  },
};

const ok2GoSlice = createSlice({
  name: "ok2Go",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setApi(state, action) {
      state.api = action.payload;
    },
    setActiveBranch(state, action) {
      state.activeBranch = action.payload;
    },
    setStaffId(state, action) {
      state.staffId = action.payload;
    },
    setClientId(state, action) {
      state.clientId = action.payload;
    },
    setJobId(state, action) {
      state.jobId = action.payload;
    },
    setActiveSection(state, action) {
      state.activeSection = action.payload;
    },
    setActiveEntity(state, action) {
      state.activeEntity = action.payload;
    },
    setActiveRowId(state, action) {
      state.activeRowId = action.payload;
    },
    setDate(state, action) {
      const { date } = action.payload;
      state.activeDate = date;
    },
    resetPage(state, action) {
      state.page = initialState.page;
    },
    reset(state, action) {
      state.api = initialState.api;
      state.activeBranch = initialState.activeBranch;
      state.activeSection = initialState.activeSection;
      state.activeEntity = initialState.activeEntity;
      state.activeRowId = initialState.activeRowId;
      state.activeDate = initialState.activeDate;
    },
  },
  extraReducers: {
    [syncOk2Go.fulfilled]: (state, action) => {},
    [syncOk2Go.rejected]: (state, action) => {},
  },
});

export const loadPage = (params, query) => (dispatch, getState) => {
  const { finalfilters, order } = params;
  let newOrder = order;
  if (!order.order) {
    newOrder = initialState.page.order;
  }
  let newParams = { ...params, order: newOrder };
  if (finalfilters) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: otherFilters,
      freeText: params.filter.freeText,
    };
  }
  const newState = {
    ...getState().ok2Go.page,
    ...newParams,
  };
  dispatch(setPage(newState));
};

export const {
  setPage,
  setApi,
  setActiveBranch,
  setActiveSection,
  setActiveEntity,
  setActiveRowId,
  setStaffId,
  setClientId,
  setDate,
  reset,
  resetPage,
  setJobId,
} = ok2GoSlice.actions;

export default ok2GoSlice.reducer;
