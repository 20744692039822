import React, { useEffect } from "react";
import "./index.css";
import "./assets/fonts/Rubik-Regular";
import "./assets/fonts/Rubik-Bold";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";


function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
    return import("./TenantsApp.js");
  } else {
    return import("./App.js");
  }
}

/**
 * Returns a string name for the environment reported to Sentry given
 * the current hostname.
 */
function getSentryEnv() {
  switch (document.location.hostname) {
    case "localhost":
      return "local";
    case "dreams-staging.matav.org.il":
      return "staging";
    case "dreams-learn.matav.org.il":
      return "learn";
    case "dreams.matav.org.il":
      return "production";
    default:
      console.error("SENTRY: unrecogized environment for sentry: Hostname: ", document.hostname);
      return null;
  }
}

// Configure sentry if not local env, or if we explicitly tell it too locally using env var.
const sentryEnv = getSentryEnv();
if (sentryEnv !== "local" || process.env.REACT_APP_SENTRY_LOG_LOCAL === "true") {
  console.info("initializing sentry")
  Sentry.init({
    environment: sentryEnv,
    dsn: "https://78bc3dca4fb979058362da32c90f0e56@o4507211185979392.ingest.de.sentry.io/4507211190108240",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
const root = createRoot(container);

// The new wrapper component that logs "rendered"
function AppWithCallbackAfterRender({ children }) {
  useEffect(() => { }, []);
  return children;
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  root.render(
    <Provider store={store}>
      <AppWithCallbackAfterRender>
        <Environment />
      </AppWithCallbackAfterRender>
    </Provider>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
