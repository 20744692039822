import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createContract,
  updateContract,
  deleteContract,
  createContractVersion,
  updateContractVersion,
  deleteContractVersion,
  getContract,
  getRecord,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeContract: {},
};

export const loadRecord = createAsyncThunk(
  "contract/loadContract",
  async (id, thunkAPI) => {
    return getRecord("settings/contracts", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const updateRecord = createAsyncThunk(
  "contract/update",
  withNotifications(
    updateContract,
    "save_contract",
    "save_contract_success",
    "save_contract_error"
  )
);

export const createRecord = createAsyncThunk(
  "contract/create",
  withNotifications(
    createContract,
    "create_contract",
    "create_contract_success",
    "create_contract_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "contract/delete",
  withNotifications(
    deleteContract,
    "delete_contract",
    "delete_contract_success",
    "delete_contract_error"
  )
);

export const createRecordVersion = createAsyncThunk(
  "contractVersion/create",
  withNotifications(
    createContractVersion,
    "create_contract_version",
    "create_contract_version_success",
    "create_contract_version_error"
  )
);

export const updateRecordVersion = createAsyncThunk(
  "contractVersion/update",
  withNotifications(
    updateContractVersion,
    "save_contract_version",
    "save_contract_version_success",
    "save_contract_version_error"
  )
);

export const deleteRecordVersion = createAsyncThunk(
  "contractVersion/delete",
  withNotifications(
    deleteContractVersion,
    "delete_contract_version",
    "delete_contract_version_success",
    "delete_contract_version_error"
  )
);

const contractSlice = createSlice({
  name: "contract",
  initialState: initialState,
  reducers: {
    setActiveContract(state, action) {
      const { record } = action.payload;
      state.activeContract = record;
    },
    reset(state, action) {
      state.error = null;
      state.activeContract = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeContract = action.payload;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeContract = data;
      state.error = error;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeContract = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },

    [deleteRecord.fulfilled]: (state, action) => {
      state.activeContract = {};
    },
    [updateRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [deleteRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const { setActiveContract, reset } = contractSlice.actions;

export default contractSlice.reducer;
