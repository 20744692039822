import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEmployeeAbsence,
  updateEmployeeAbsence,
  getEmployeeAbsences,
  deleteEmployeeAbsence,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeAbsence: {},
  absences: [],
};

export const loadAbsences = createAsyncThunk(
  "employeeAbsences/load",
  async (employeeId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getEmployeeAbsences({ employeeId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get Absence", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createAbsence = createAsyncThunk(
  "employeeAbsences/create",
  withNotifications(
    createEmployeeAbsence,
    "create_employee_absence",
    "create_employee_absence_success",
    "create_employee_absence_error"
  )
);

export const updateAbsence = createAsyncThunk(
  "employeeAbsences/update",
  withNotifications(
    updateEmployeeAbsence,
    "update_employee_absence",
    "update_employee_absence_success",
    "update_employee_absence_error"
  )
);

export const deleteAbsence = createAsyncThunk(
  "employeeAbsences/delete",
  withNotifications(
    deleteEmployeeAbsence,
    "delete_employee_absence",
    "delete_employee_absence_success",
    "delete_employee_absence_error"
  )
);

const employeeAbsencesSlice = createSlice({
  name: "employeeAbsences",
  initialState: initialState,
  reducers: {
    reset(state, action) {
      state.absences = initialState.absences;
      state.activeAbsence = initialState.activeAbsence;
    },
  },
  extraReducers: {
    [loadAbsences.fulfilled]: (state, action) => {
      state.absences = action.payload.items;
    },
    [loadAbsences.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const { reset } = employeeAbsencesSlice.actions;

export default employeeAbsencesSlice.reducer;
