import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEmployeePersonalFile,
  getEmployeePersonalFile,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  personalFile: [],
};

export const loadPersonalFile = createAsyncThunk(
  "employeePersonalFile/load",
  async (employeeId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getEmployeePersonalFile({ employeeId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get Personal File", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createPersonalFileComment = createAsyncThunk(
  "employeePersonalFile/create",
  withNotifications(
    createEmployeePersonalFile,
    "create_employee_personal_file",
    "create_employee_personal_file_success",
    "create_employee_personal_file_error"
  )
);

const employeeDocumentationSlice = createSlice({
  name: "employeePersonalFile",
  initialState: initialState,
  extraReducers: {
    [loadPersonalFile.fulfilled]: (state, action) => {
      state.personalFile = action.payload.items.sort((a, b) =>
        b.executed_at.localeCompare(a.executed_at)
      );
    },
    [loadPersonalFile.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default employeeDocumentationSlice.reducer;
