import { listEmployees,updateSubEmployeesStaffWorkerInCharge,getSettingsData} from "../../api/server.js"
import { SerializedError,  createAsyncThunk,createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../redux/store.js";
import { notifyError, withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk< {
  items: [];state : RootState
},any>(
    'employeeSubEmployees/loadPage',
    async (listParams,thunkAPI) =>{
        const {data,error} = await listEmployees(listParams)
        if (error) {
            thunkAPI.dispatch(
              notifyError({ title: "Load ProfEmployeeeSubEmployees", message: error })
            );
          }
          return data;
    }
);

// asybc thunk for commit changes calling updateSubEmployeesStaffWorkerInCharge with notification on screen with result

export const commitChanges = createAsyncThunk(
  'employeeSubEmployees/updateStaffWorkerInCharge',
  withNotifications(
    async (params:any,thunkAPI:any)=>{
      return updateSubEmployeesStaffWorkerInCharge(params)

    },
    "update_staff_worker",
    "update_staff_worker_success",
    "update_staff_worker_error",
  )
)

// servicea areas for the filter 
export const getServiceAreas = createAsyncThunk(
  'employeeSubPatients/getServiceAreas',
    async (params:any,thunkAPI:any)=>{
      params = { name:"system_tables/service_areas" }
      const {error,data} = await getSettingsData(params)
      if (error) {
        thunkAPI.dispatch(
            notifyError({title:"Load Service Areas", message:error})
        );
      }
      return data;
    }
)

interface page { current:number,pageSize:number,filter:any,finalfilters:any,order:any,query:any,items:any[],fields:string,total:number,employeeId:number}
interface stateSlice { page:page,templates:{},error:null|SerializedError,serviceAreas:any}
const initialPageSize = 10; 
const initialState:stateSlice = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
      $and: [ { $or: [{ status: 1 }, { status: 3 }] }],
    },
    finalfilters: {
      status: [],
      employee_type_id: [],
      service_area:[],
    },
    order: {
      field: "surename",
      order: "ascend",
    },
    query: {
      service_area_ids: [],
    },
    items: [],
    fields:
      "first_name,surename,local_id_number,foreign,contact_info,employee_type_id,address,avatar_url,id,active,work_periods,service_area,staff_worker_in_charge_id,status",
    total:0,
    employeeId:0,
  },
  templates: {
    items: [],
  },
  error:null,
  serviceAreas:null,
};

const subEmployeesSlice = createSlice({
  name:"employeeSubEmployees",
  initialState:initialState,
  reducers: {
      reset(state,action) {
      state.page = initialState.page;
      },
      setPage(state,{payload:page}) {
        state.page = page;
      },
      setTextFilter(state, action) {
        const { text } = action.payload;
        state.page = {
          ...state.page,
          filter: {
            ...state.page.filter,
            freeText: text,
          },
        };
      },
  },
  extraReducers: (builder)=>{
    builder.addCase(fetchPage.fulfilled, (state,action) =>{
      state.page = {...state.page,...action.payload};
    });
    builder.addCase(fetchPage.rejected,(state,action)=>{
      state.error=action.error;
    });
    builder.addCase(getServiceAreas.fulfilled,(state,action)=>{
      state.serviceAreas = action.payload;
    })
    builder.addCase(getServiceAreas.rejected,(state,action)=>{
      state.error = action.error;
    })
  }
})

export const { setPage,reset } = subEmployeesSlice.actions;

// at any page load add the condition of staff_worker_in_charge_id: employeeID

export const loadPage = (params:any,query:any,employeeID:any)=> (dispatch:any,getState:any)=>{
  console.log(params);
  const {finalfilters,order} = params;

  let newOrder = order;
  if (!order.order) {
    newOrder = initialState.page.order;
  }

  let filter:any = {
    freeText: params.filter.freeText,
  };

   let temp:any;
   let without:any;
  
   if(params && params.filter["$and"]){
      without = params.filter["$and"].filter((i:any) => !i.staff_worker_in_charge_id);
      temp = [
          ...without,
          { staff_worker_in_charge_id: employeeID },
        ]
  } else {

  temp= [{ staff_worker_in_charge_id: employeeID }];
  }
  
  filter["$and"] = temp
  
  let newParams = { ...params, order: newOrder, filter };

  if (finalfilters && !finalfilters.status) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: [{ $and: initialState.page.filter["$and"] }, ...otherFilters,{ staff_worker_in_charge_id: employeeID }],
      freeText: params.filter.freeText,
    };
  }
  
  const newState = {
    ...getState().employeeSubEmployees.page,
    ...newParams,
    employeeId:employeeID,
  };
  const scopePrefix = getState().user.scopePrefix;
  const newQuery = query ? query : { branches: newState.finalfilters.branches };
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, query: newQuery }));  
}

export default subEmployeesSlice.reducer;