import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRecord } from "../../api/server";

const initialState = {
  error: null,
  activeUser: {},
};

export const loadRecord = createAsyncThunk(
  "tenantUser/loadUser",
  async (id, thunkAPI) => {
    return getRecord("users", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

const tenantUserSlice = createSlice({
  name: "tenantUser",
  initialState: initialState,
  reducers: {
    setActiveUser(state, action) {
      const { record } = action.payload;
      state.activeUser = record;
    },
    reset(state, action) {
      state.error = null;
      state.activeUser = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeUser = action.payload;
    },
  },
});

export const { setActiveUser, reset } = tenantUserSlice.actions;

export default tenantUserSlice.reducer;
