import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branches: null,
  roles: null,
  genders: null,
  employee_types: null,
  client_categories: null,
  care_demands: null,
  service_types: null,
  order_service_types: null,
  salary_symbols: null,
  salary_column_settings: null,
  client_tariffs: null,
  patient_status: null,
  permissions: null,
  banks: null,
  familyStatus: null,
  healthFunds: null,
  countries: null,
  religions: null,
  education: null,
  certification: null,
  patient_categories: null,
  relative_types: null,
  absence_reasons: null,
  branch_groups: null,
  refund_reasons: null,
  languages: null,
  hospitalization_institutions: null,
  end_work_period_reason: null,
  courses: null,
  departments: null,
  professional_roles: null,
  asset_categories: null,
  asset_sub_categories: null,
  asset_statuses: null,
  no_source: null,
};

const sourcesSlice = createSlice({
  name: "sources",
  initialState: initialState,
  reducers: {
    setActiveSource(state, { payload: source }) {
      state[source.name] = { ...state[source.name], ...source };
    },
  },
});

export const { setActiveSource } = sourcesSlice.actions;

export default sourcesSlice.reducer;
