import Enlang from "./entries/en-US";
import Helang from "./entries/he-IL";
import englishLang from "../assets/flags/uk.svg";
import hebrewLang from "../assets/flags/israel.svg";

const AppLocale = {
  en: Enlang,
  he: Helang
};

const language = "hebrew";
const languageConfig = {
  defaultLanguage: language,
  options: [
    {
      languageId: "hebrew",
      locale: "he",
      text: "Hebrew",
      icon: hebrewLang,
      direction: "rtl"
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
      direction: "ltr"
    }
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = languageConfig.options[0];
  languageConfig.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}

export { AppLocale, languageConfig };
