import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createPatientReference,
  updatePatientReference,
  getPatientReferences,
  deletePatientReference,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeReference: {},
  references: [],
};

export const loadReferences = createAsyncThunk(
  "patientReferences/load",
  async (patientId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getPatientReferences({ patientId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get reference", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createReference = createAsyncThunk(
  "patientReferences/create",
  withNotifications(
    createPatientReference,
    "create_patient_reference",
    "create_patient_reference_success",
    "create_patient_reference_error"
  )
);

export const updateReference = createAsyncThunk(
  "patientReferences/update",
  withNotifications(
    updatePatientReference,
    "update_patient_reference",
    "update_patient_reference_success",
    "update_patient_reference_error"
  )
);

export const deleteReference = createAsyncThunk(
  "patientReferences/delete",
  withNotifications(
    deletePatientReference,
    "delete_patient_reference",
    "delete_patient_reference_success",
    "delete_patient_reference_error"
  )
);

const patientReferencesSlice = createSlice({
  name: "patientReferences",
  initialState: initialState,
  extraReducers: {
    [loadReferences.fulfilled]: (state, action) => {
      state.references = action.payload.items;
    },
    [loadReferences.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default patientReferencesSlice.reducer;
