import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  updateSystemTable,
  deleteSystemTableItem,
  createSystemTableItem,
  changeSystemTableOrder,
} from "../../api/server";

import { withNotifications } from "../notifications/notificationsSlice";

export const createTableItem = createAsyncThunk(
  "systemTable/create_item",
  withNotifications(
    createSystemTableItem,
    "create_system_table_item",
    "create_system_table_item_success",
    "create_system_table_item_error"
  )
);

export const updateTableItem = createAsyncThunk(
  "systemTable/update_item",
  withNotifications(
    updateSystemTable,
    "update_system_table_item",
    "update_system_table_item_success",
    "update_system_table_item_error"
  )
);

export const deleteTableItem = createAsyncThunk(
  "systemTable/delete_item",
  withNotifications(
    deleteSystemTableItem,
    "delete_system_table_item",
    "delete_system_table_item_success",
    "delete_system_table_item_error"
  )
);

export const changeTableOrder = createAsyncThunk(
  "systemTable/change_order",
  withNotifications(
    changeSystemTableOrder,
    "change_system_table_order",
    "change_system_table_order_success",
    "change_system_table_order_error"
  )
);

const initialState = {
  page: {},
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  extraReducers: {
    [createTableItem.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createTableItem.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateTableItem.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateTableItem.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [deleteTableItem.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [deleteTableItem.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [changeTableOrder.fulfilled]: (state, action) => {
      state.error = action.error;
    },
    [changeTableOrder.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default settingsSlice.reducer;
