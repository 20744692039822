import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTariff,
  deleteTariff,
  updateTariff,
  createTariffVersion,
  updateTariffVersion,
  deleteTariffVersion,
  getRecord,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeTariff: {},
};

export const loadRecord = createAsyncThunk(
  "tariffDetails/loadTariff",
  async (id, thunkAPI) => {
    return getRecord("settings/salary/tariffs", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const createRecord = createAsyncThunk(
  "tariffDetails/create",
  withNotifications(
    createTariff,
    "create_tariff",
    "create_tariff_success",
    "create_tariff_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "tariffDetails/delete",
  withNotifications(
    deleteTariff,
    "delete_tariff",
    "delete_tariff_success",
    "delete_tariff_error"
  )
);

export const updateRecord = createAsyncThunk(
  "tariffDetails/update",
  withNotifications(
    updateTariff,
    "update_tariff",
    "update_tariff_success",
    "update_tariff_error"
  )
);

export const createRecordVersion = createAsyncThunk(
  "tariffVersion/create",
  withNotifications(
    createTariffVersion,
    "save_tariff",
    "save_tariff_success",
    "save_tariff_error"
  )
);

export const updateRecordVersion = createAsyncThunk(
  "tariffVersion/update",
  withNotifications(
    updateTariffVersion,
    "save_tariff",
    "save_tariff_success",
    "save_tariff_error"
  )
);

export const deleteRecordVersion = createAsyncThunk(
  "tariffVersion/delete",
  withNotifications(
    deleteTariffVersion,
    "delete_tariff_version",
    "delete_tariff_version_success",
    "delete_tariff_version_error"
  )
);

const tariffSlice = createSlice({
  name: "tariffDetails",
  initialState: initialState,
  reducers: {
    setActiveTariff(state, action) {
      const { record } = action.payload;
      state.activeTariff = record;
    },
    reset(state, action) {
      state.error = null;
      state.activeTariff = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeTariff = action.payload;
    },
    [updateRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeTariff = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeTariff = {};
    },
    [deleteRecordVersion.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [deleteRecordVersion.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const { setActiveTariff, reset } = tariffSlice.actions;

export default tariffSlice.reducer;
