import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createRole, updateRole, deleteRole } from "../../api/server";

import NotificationError from "../notifications/error";
import {
  notifySuccess,
  withNotifications,
} from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeRole: {},
};

export const createRecord = createAsyncThunk(
  "role/create",
  withNotifications(
    createRole,
    "create_role",
    "create_role_success",
    "create_role_error"
  )
);

export const updateRecord = createAsyncThunk(
  "role/update",
  withNotifications(
    updateRole,
    "update_role",
    "update_role_success",
    "update_role_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "role/delete",
  withNotifications(
    deleteRole,
    "delete_role",
    "delete_role_success",
    "delete_role_error"
  )
);

const roleDetailsSlice = createSlice({
  name: "roleDetails",
  initialState: initialState,
  reducers: {
    setActiveRole(state, action) {
      state.activeRole = action.payload;
    },
    reset(state, action) {
      state.error = null;
      state.activeRole = {};
    },
  },
  extraReducers: {
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeRole = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeRole = data;
      state.error = error;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeRole = {};
    },
    [deleteRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const { reset, setActiveRole } = roleDetailsSlice.actions;

export default roleDetailsSlice.reducer;
