import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  notifyError,
  withNotifications,
  notifySuccess,
} from "../notifications/notificationsSlice";
import { fetchUserProfile } from "../user/UserSlice";
import Auth from "../../api/auth";

import {
  acceptMyInvitation,
  listInvitations,
  deleteRecord,
  createInvitation,
} from "../../api/server";

export const fetchPage = createAsyncThunk(
  "registration/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listInvitations(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load invitations", message: error })
      );
    }
    return data;
  }
);

export const createInvite = createAsyncThunk(
  "registration/createInvitation",
  withNotifications(
    createInvitation,
    "create_invitation",
    "create_invitation_success",
    "create_invitation_error"
  )
);

export const resendInvite = createAsyncThunk(
  "registration/resendInvitation",
  withNotifications(
    createInvitation,
    "resend_invitation",
    "resend_invitation_success",
    "resend_invitation_error"
  )
);

const deleteInvite = (record) => deleteRecord("invitations", record);

export const deleteInvitation = createAsyncThunk(
  "registration/deleteInvitation",
  withNotifications(
    deleteInvite,
    "delete_invitation",
    "delete_invitation_success",
    "delete_invitation_error"
  )
);

const initialPageSize = 10;
const initialState = {
  registrationInProgress: false,
  error: null,
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "name",
      order: "descend",
    },
    items: [],
  },
};

export const acceptInvitation = createAsyncThunk(
  "registration/acceptInvitation",
  acceptMyInvitation
);

const registrationSlice = createSlice({
  name: "registration",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    setRegistrationInProgress(state, { payload }) {
      state.registrationInProgress = payload;
    },
  },

  extraReducers: {
    [acceptInvitation.fulfilled]: (state, { payload: { error, data } }) => {
      state.invitationAccepted = true;
      state.registrationInProgress = false;
      state.error = error;
    },
    [acceptInvitation.rejected]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createInvite.fulfilled]: (state, { payload: { error, data } }) => {
      state.page.items.push(data);
      state.error = error;
    },
    [createInvite.rejected]: (state, action) => {
      state.error = action.error;
    },
    [resendInvite.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [resendInvite.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteInvitation.fulfilled]: (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const {
  setPage,
  setRegistrationInProgress,
  setError,
} = registrationSlice.actions;

export default registrationSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().registration.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().registration.page));
};

export const finishRegistration = createAsyncThunk(
  "registration/finishRegistration",
  async ({ tenantId, email, newPassword }, thunkAPI) => {
    thunkAPI.dispatch(setRegistrationInProgress(true));
    try {
      await Auth.signInWithLink(tenantId, email);
      await thunkAPI.dispatch(acceptInvitation(newPassword));
      await Auth.signOut();
      thunkAPI.dispatch(
        notifySuccess({
          title: "registration",
          message: "registration_success",
        })
      );
      thunkAPI.dispatch(setRegistrationInProgress(false));
    } catch (err) {
      const message =
        err.code === "auth/invalid-email" ||
        err.code === "auth/invalid-action-code" ||
        err.code === "auth/too-many-requests"
          ? err.code
          : err.message;
      thunkAPI.dispatch(
        notifyError({ title: "registration", message: message })
      );
      thunkAPI.dispatch(setRegistrationInProgress(false));
    } finally {
    }
  }
);

export const linkProvider = createAsyncThunk(
  "registration/linkProvider",
  async (
    { tenantId, authProvider, providerTenant, email, href, history },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setRegistrationInProgress(true));
    try {
      await Auth.linkProvider(
        tenantId,
        authProvider,
        providerTenant,
        email,
        href,
        history
      );
      await thunkAPI.dispatch(acceptInvitation(""));
      thunkAPI.dispatch(
        notifySuccess({
          title: "registration",
          message: "registration_success",
        })
      );
      thunkAPI.dispatch(setRegistrationInProgress(false));
      thunkAPI.dispatch(fetchUserProfile());
    } catch (err) {
      const message =
        err.code === "auth/invalid-email" ||
        err.code === "auth/invalid-action-code" ||
        err.code === "auth/too-many-requests"
          ? err.code
          : err.message;

      thunkAPI.dispatch(
        notifyError({ title: "registration", message: message })
      );
      thunkAPI.dispatch(setRegistrationInProgress(false));
    } finally {
    }
  }
);
