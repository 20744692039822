import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listUsers, getRoles } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "users/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listUsers(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load users", message: error }));
    }
    return data;
  }
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};

export const fetchRoles = createAsyncThunk(
  "users/loadRoles",
  async (listParams, thunkAPI) => {
    const { error, data } = await getRoles();
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load roles", message: error }));
    }
    return data;
  }
);

const initialPageSize = 100;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
    finalfilters: {
      roles: [],
      branches: [],
    },
  },
  loading: false,
  roles: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setLoading(state, { payload: loading }) {
      state.loading = loading;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.loading = initialState.loading;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchRoles.fulfilled]: (state, action) => {
      const data = action.payload;
      state.roles = data;
    },
    [fetchRoles.rejected]: (state, action) => {
      state.error = action.payload;
    },
    "userDetails/activate/fulfilled": onItemChanged,
    "userDetails/deactivate/fulfilled": onItemChanged,
    "userDetails/update/fulfilled": onItemChanged,
    "userDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setLoading, setPage, reset } = usersSlice.actions;

export default usersSlice.reducer;

export const loadPage = (params, query) => (dispatch, getState) => {
  const newState = {
    ...getState().users.page,
    ...params,
  };
  const newQuery = query
    ? query
    : {
        branches: newState.finalfilters.branches,
        roles: newState.finalfilters.roles,
      };

  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, query: newQuery }));
};

export const reload = () => (dispatch, getState) => {
  dispatch(loadPage(getState().users.page));
};

export const loadRoles = () => (dispatch, getState) => {
  dispatch(fetchRoles());
};
