import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listEmployeeWorkDiaries, sendWorkDiaries } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import moment from "moment";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchEmployeeWorkDiaries = createAsyncThunk(
  "employeeWorkDiaries/load",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployeeWorkDiaries(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load employee orders", message: error })
      );
    }
    return data;
  }
);

export const sendDiaryToEmployee = createAsyncThunk(
  "employeeWorkDiaries/sendDiary",
  withNotifications(
    sendWorkDiaries,
    "send_diary_to_employee",
    "send_diary_to_employee_success",
    "send_diary_to_employee_error"
  )
);

export const sendDiaryToEmployees = createAsyncThunk(
  "employeeWorkDiaries/sendDiaries",
  withNotifications(
    sendWorkDiaries,
    "send_diary_to_employees",
    "send_diary_to_employees_success",
    "send_diary_to_employees_error"
  )
);

const initialPageSize = 30;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {},
    items: [],
    query: {
      from: null,
      to: null,
    },
  },
  activeYear: null,
  activeMonth: null,
  activeEmployee: {},
};

const employeeWorkDiariesSlice = createSlice({
  name: "employeeWorkDiaries",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setActiveEmployee(state, { payload: activeEmployee }) {
      state.activeEmployee = activeEmployee;
    },
    setYear(state, action) {
      const { year } = action.payload;
      state.activeYear = year;
    },
    setMonth(state, action) {
      const { month } = action.payload;
      state.activeMonth = month;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeYear = initialState.activeYear;
      state.activeMonth = initialState.activeMonth;
      state.activeEmployee = initialState.activeEmployee;
    },
  },
  extraReducers: {
    [fetchEmployeeWorkDiaries.fulfilled]: (state, action) => {
      state.page = {
        ...state.page,
        ...action.payload,
      };
    },
    [fetchEmployeeWorkDiaries.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const { setPage, setYear, setMonth, reset, setActiveEmployee } =
  employeeWorkDiariesSlice.actions;

export default employeeWorkDiariesSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const currentMonth = moment();
  currentMonth.set("year", +getState().employeeWorkDiaries.activeYear);
  currentMonth.set("month", +getState().employeeWorkDiaries.activeMonth - 1);
  const from = moment(currentMonth).startOf("month");
  const to = moment(currentMonth).endOf("month");

  const newState = {
    ...getState().employeeWorkDiaries.page,
    ...params,
    query: {
      from: moment.utc(from).format(),
      to: moment.utc(to).format(),
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  const activeEmployee = getState().employeeDetails.activeEmployee;
  const employeeId = activeEmployee.id;
  const branchId = getState().employeeDetails.activeEmployee["active_branches"]
    ? getState().employeeDetails.activeEmployee["active_branches"][0].id
    : null;
  if (branchId) {
    dispatch(setPage(newState));
    dispatch(
      fetchEmployeeWorkDiaries({
        ...newState,
        scopePrefix,
        employeeId,
        branchId,
      })
    );
  }
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().employeeWorkDiaries.page));
};
