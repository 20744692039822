import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listEmployeeScheduling,
  transferSchedulingForPeriod,
} from "../../api/server";
import {
  notifyError,
  withNotifications,
} from "../notifications/notificationsSlice";

export const fetchScheduling = createAsyncThunk(
  "employeeScheduling/load",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployeeScheduling(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load scheduling", message: error })
      );
    }
    return data;
  }
);

export const transferPeriod = createAsyncThunk(
  "employeeScheduling/transferPeriod",
  withNotifications(
    transferSchedulingForPeriod,
    "transfer_period",
    "transfer_period_success",
    "transfer_period_error"
  )
);

const initialPageSize = 300;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {},
    items: [],
    effective_orders: [],
  },
};

const schedulingSlice = createSlice({
  name: "employeeScheduling",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchScheduling.fulfilled]: (state, action) => {
      let items = [];
      let replacements = [];

      if (action.payload.items && action.payload.items.length > 0) {
        action.payload.items.forEach((item, i) => {
          let temp = { ...item };

          if (
            action.payload.effective_orders &&
            action.payload.effective_orders.length > 0
          ) {
            const order = action.payload.effective_orders.filter(
              (i) => i.id === item.order_id
            )[0];
            temp["order"] = order;
          }
          items.push(temp);
        });
      }

      if (
        action.payload.replacements &&
        action.payload.replacements.length > 0
      ) {
        action.payload.replacements.forEach((item, i) => {
          let temp = { ...item };

          if (
            action.payload.effective_orders &&
            action.payload.effective_orders.length > 0
          ) {
            const order = action.payload.effective_orders.filter(
              (i) => i.id === item.order_id
            )[0];
            temp["order"] = order;
          }
          replacements.push(temp);
        });
      }
      state.page = { ...state.page, ...action.payload, items, replacements };
    },
    [fetchScheduling.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [transferPeriod.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});
export const { setPage, reset } = schedulingSlice.actions;

export default schedulingSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().employeeScheduling.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  const employeeId = getState().employeeDetails.activeEmployee.id;
  const branchId = getState().employeeDetails.activeEmployee["active_branches"]
    ? getState().employeeDetails.activeEmployee["active_branches"][0].id
    : null;
  if (branchId) {
    dispatch(setPage(newState));
    dispatch(
      fetchScheduling({ ...newState, scopePrefix, employeeId, branchId })
    );
  }
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().employeeScheduling.page));
};
