import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchList } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "dashboards/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await fetchList("dashboards", listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load dashboards", message: error })
      );
    }
    return data;
  }
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    items: [],
  }
};

const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    }
  },
});

export const { setPage } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().dashboards.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().dashboards.page));
};
