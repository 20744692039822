import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unflatten } from "flat";
import {
  createBranch,
  updateBranch,
  deleteBranch,
  getRecord,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeBranch: {},
  changedItems: {
    changed: [],
    values: [],
  },
};

export const loadRecord = createAsyncThunk(
  "branchDetails/loadBranch",
  async (id, thunkAPI) => {
    return getRecord("branches", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const commitChanges = createAsyncThunk(
  "clientDetails/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { branchDetails } = thunkAPI.getState();
      const { activeBranch } = branchDetails;
      const { id } = activeBranch;
      const {
        changedItems: { changed, values },
      } = branchDetails;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});

      return updateBranch({
        id,
        ...unflatten(toUpdate),
      });
    },
    "save_branch",
    "save_branch_success",
    "save_branch_error"
  )
);

export const updateRecord = createAsyncThunk(
  "branchDetails/update",
  withNotifications(
    updateBranch,
    "save_branch",
    "save_branch_success",
    "save_branch_error"
  )
);

export const createRecord = createAsyncThunk(
  "branchDetails/create",
  withNotifications(
    createBranch,
    "create_branch",
    "create_branch_success",
    "create_branch_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "branchDetails/delete",
  withNotifications(
    deleteBranch,
    "delete_branch",
    "delete_branch_success",
    "delete_branch_error"
  )
);

const branchDetailsSlice = createSlice({
  name: "branchDetails",
  initialState: initialState,
  reducers: {
    setActiveBranch(state, action) {
      const { record } = action.payload;
      state.activeBranch = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeBranch = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeBranch = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeBranch = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeBranch = data;
      state.error = error;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeBranch = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },

    [deleteRecord.fulfilled]: (state, action) => {
      state.activeBranch = {};
    },
  },
});

export const {
  setActiveBranch,
  setChangedFormItems,
  reset,
} = branchDetailsSlice.actions;

export default branchDetailsSlice.reducer;
