import antdEn from "antd/locale/he_IL";
import heMessages from "../locales/he_IL.json";

const HeLang = {
  messages: {
    ...heMessages,
  },
  antd: antdEn,
  locale: "he-IL",
  direction: "rtl",
};
export default HeLang;
