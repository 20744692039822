import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unflatten } from "flat";
import {
  createTenant,
  updateTenant,
  deleteTenant,
  getRecord,
  activateTenant,
  deactivateTenant,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeTenant: {},
  changedItems: {
    changed: [],
    values: [],
  },
};

export const loadRecord = createAsyncThunk(
  "tenant/load",
  async (id, thunkAPI) => {
    return getRecord("tenants", id).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const commitChanges = createAsyncThunk(
  "tenant/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { tenant } = thunkAPI.getState();
      const { activeTenant } = tenant;
      const { id } = activeTenant;
      const {
        changedItems: { changed, values },
      } = tenant;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});

      return updateTenant({
        id,
        ...unflatten(toUpdate),
      });
    },
    "save_tenant",
    "save_tenant_success",
    "save_tenant_error"
  )
);

export const createRecord = createAsyncThunk(
  "tenant/create",
  withNotifications(
    createTenant,
    "create_tenant",
    "create_tenant_success",
    "create_tenant_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "tenant/delete",
  withNotifications(
    deleteTenant,
    "delete_tenant",
    "delete_tenant_success",
    "delete_tenant_error"
  )
);

export const activateRecord = createAsyncThunk(
  "tenant/activate",
  withNotifications(
    activateTenant,
    "activate_tenant",
    "activate_tenant_success",
    "activate_tenant_error"
  )
);

export const deactivateRecord = createAsyncThunk(
  "tenant/deactivate",
  withNotifications(
    deactivateTenant,
    "deactivate_tenant",
    "deactivate_tenant_success",
    "deactivate_tenant_error"
  )
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState: initialState,
  reducers: {
    setActiveTenant(state, action) {
      const { record } = action.payload;
      state.activeTenant = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeTenant = {};
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.activeTenant = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeTenant = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeTenant = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },

    [deleteRecord.fulfilled]: (state, action) => {
      state.activeTenant = {};
    },
  },
});

export const {
  setactiveTenant,
  setChangedFormItems,
  reset,
} = tenantSlice.actions;

export default tenantSlice.reducer;
