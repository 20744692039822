import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unflatten } from "flat";
import {
  createEmployee,
  updateEmployee,
  deleteEmployee,
  getEmployee,
  assignEmployeeToBranch,
  getGlobalEmployee,
  activateEmployee,
  deactivateEmployee,
  createEmployeeWorkPeriods,
  updateEmployeeWorkPeriods,
} from "../../api/server";
import NotificationError from "../notifications/error";
import {
  notifySuccess,
  withNotifications,
} from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeEmployee: {},
  isProfessional: null,
  changedItems: {
    changed: [],
    values: {},
  },
  assets: [],
};

export const loadRecord = createAsyncThunk(
  "employeeDetails/loadEmployee",
  async (params, thunkAPI) => {
    return getEmployee(params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load employee", error);
      }
      return data;
    });
  }
);

export const loadGlobalEmployee = createAsyncThunk(
  "employeeDetails/loadGlobalEmployee",
  async (params, thunkAPI) => {
    return getGlobalEmployee(params.scopePrefix, params.filter).then(
      ({ error, data }) => {
        if (error) {
          // throw new NotificationError("Load employee", error);
        }
        return data;
      }
    );
  }
);

export const commitChanges = createAsyncThunk(
  "employeeDetails/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { scopePrefix } = thunkAPI.getState().user;

      const { employeeDetails } = thunkAPI.getState();
      const { activeEmployee, isProfessional } = employeeDetails;
      const { id } = activeEmployee;
      const {
        changedItems: { changed, values },
      } = employeeDetails;
      let toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      if (changed.includes("local_id_number")) {
        toUpdate["local_id_number"] = toUpdate["local_id_number"].padStart(
          9,
          "0"
        );
      }

      const employee = isProfessional
        ? { id, ...unflatten(toUpdate) }
        : {
          id,
          ...unflatten(toUpdate),
          professional_role_id: null,
          professional_role_position_percentage: null,
          local_id_number:
            values.local_id_number === ""
              ? null
              : values.local_id_number?.padStart(9, "0"),
        };

      return updateEmployee({
        employee: employee,
        scopePrefix,
      });
    },
    "save_employee",
    "save_employee_success",
    "save_employee_error"
  )
);

export const createRecord = createAsyncThunk(
  "employeeDetails/create",
  withNotifications(
    createEmployee,
    "create_employee",
    "create_employee_success",
    "create_employee_error"
  )
);

export const assignEmployee = createAsyncThunk(
  "employeeDetails/assign",
  withNotifications(
    assignEmployeeToBranch,
    "assign_employee",
    "assign_employee_success",
    "assign_employee_error"
  )
);

export const activateRecord = createAsyncThunk(
  "employeeDetails/activate",
  withNotifications(
    activateEmployee,
    "activate_employee",
    "activate_employee_success"
  )
);

export const deactivateRecord = createAsyncThunk(
  "employeeDetails/deactivate",
  withNotifications(
    deactivateEmployee,
    "deactivate_employee",
    "deactivate_employee_success"
  )
);

export const deleteRecord = createAsyncThunk(
  "employeeDetails/delete",
  async (params, thunkAPI) => {
    return deleteEmployee(params).then(({ error, data }) => {
      if (error) {
        throw new NotificationError("Delete employee", error);
      }
      thunkAPI.dispatch(
        notifySuccess({
          title: "delete_employee",
          message: "delete_employee_success",
        })
      );
      return data;
    });
  }
);

export const createWorkPeriods = createAsyncThunk(
  "employeeDetails/createEmployeeWorkPeriods",
  withNotifications(
    createEmployeeWorkPeriods,
    "create_work_periods",
    "create_work_periods_success",
    "create_work_periods_error"
  )
);

export const updateWorkPeriods = createAsyncThunk(
  "employeeDetails/updateEmployeeWorkPeriods",
  withNotifications(
    updateEmployeeWorkPeriods,
    "update_work_periods",
    "update_work_periods_success",
    "update_work_periods_error"
  )
);

const employeeDetailsSlice = createSlice({
  name: "employeeDetails",
  initialState: initialState,
  reducers: {
    setActiveEmployee(state, action) {
      const { record } = action.payload;
      state.activeEmployee = record;
    },
    setActiveGeo(state, action) {
      const { record } = action.payload;
      state.activeGeo = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    setIsPro(state, action) {
      state.isProfessional = action.payload;
    },
    reset(state, action) {
      state.error = null;
      state.activeEmployee = {};
      state.activeGeo = {
        longtitude: 35.21371,
        latitude: 31.768319,
        address_line: null,
      };
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.changedItems = { changed: [], values: [] };
      state.activeEmployee = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeEmployee = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeEmployee = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [activateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeEmployee = { ...state.activeEmployee, active: true };
      state.error = error;
    },
    [deactivateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeEmployee = { ...state.activeEmployee, active: false };
      state.error = error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeEmployee = {};
    },
    [createWorkPeriods.fulfilled]: (state, { payload: { error, data } }) => {
      const { work_periods } = state.activeEmployee;
      work_periods.splice(work_periods.length, 1, data);
      state.activeEmployee = {
        ...state.activeEmployee,
        ...state.changedItems.values,
        work_periods,
      };
      state.error = error;
    },
    [createWorkPeriods.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updateWorkPeriods.fulfilled]: (state, { payload: { error, data } }) => {
      const { changed, values } = state.changedItems;
      const toRemove = ["startEmployment", "endEmployment"];
      const { startEmployment, endEmployment, ...newValues } = values;
      const newChanged = changed.filter(
        (c, i) => toRemove.indexOf(changed[i]) < 0
      );
      state.changedItems = { changed: newChanged, values: newValues };

      const { work_periods } = state.activeEmployee;
      work_periods.splice(work_periods.length, 1, data);
      state.activeEmployee = {
        ...state.activeEmployee,
        ...state.changedItems.values,
        work_periods,
      };
      state.error = error;
    },
    [updateWorkPeriods.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  setActiveEmployee,
  setActiveGeo,
  setChangedFormItems,
  setIsPro,
  reset,
} = employeeDetailsSlice.actions;

export const isEmployeeActive = (record) => {
  return record && record.status !== 2;
};

export const getEmployeeStatus = (record) => {
  let status = "success";
  switch (record.status) {
    case 1:
      status = "success";
      break;

    case 2:
      status = "error";
      break;

    case 3:
      status = "default";
      break;

    default:
      break;
  }

  return status;
};

export default employeeDetailsSlice.reducer;
