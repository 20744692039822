import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPatientPersonalFile,
  createPatientPersonalFile,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  personalFile: [],
};

export const loadPersonalFile = createAsyncThunk(
  "patientPersonalFile/load",
  async (patientId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getPatientPersonalFile({ patientId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get Personal File", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createPersonalFileComment = createAsyncThunk(
  "patientPersonalFile/create",
  withNotifications(
    createPatientPersonalFile,
    "create_patient_personal_file",
    "create_patient_personal_file_success",
    "create_patient_personal_file_error"
  )
);

const patientPersonalFileSlice = createSlice({
  name: "patientPersonalFile",
  initialState: initialState,
  extraReducers: {
    [loadPersonalFile.fulfilled]: (state, action) => {
      state.personalFile = action.payload.items.sort((a, b) =>
        b.executed_at.localeCompare(a.executed_at)
      );
    },
    [loadPersonalFile.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default patientPersonalFileSlice.reducer;
