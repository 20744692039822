import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createOrder,
  updateOrder,
  getOrder,
  deleteOrder,
  getClient,
  startTrom,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";
import { unflatten } from "flat";

const initialState = {
  error: null,
  activeOrder: {},
  changedItems: {
    changed: [],
    values: [],
  },
  clientsByID: {},
};

export const loadOrder = createAsyncThunk(
  "orderDetails/loadOrder",
  async (params, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getOrder({ ...params, scopePrefix }).then(({ error, data }) => {
      if (error) {
        thunkAPI.dispatch(notifyError({ title: "Get Order", message: error }));
      }
      return data;
    });
  }
);

export const createRecord = createAsyncThunk(
  "orderDetails/create",
  withNotifications(
    createOrder,
    "create_order",
    "create_order_success",
    "create_order_error"
  )
);

export const loadClient = createAsyncThunk(
  "orderDetails/loadClient",
  withNotifications(getClient, "create_order", null, "create_order_error")
);

export const commitChanges = createAsyncThunk(
  "orderDetails/commitChanges",
  withNotifications(
    async (params, thunkAPI) => {
      const { scopePrefix } = thunkAPI.getState().user;
      const {
        orderDetails: {
          changedItems: { changed, values },
          activeOrder: { id, patient_id },
        },
      } = thunkAPI.getState();
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      if (toUpdate.no_end_date === true) {
        toUpdate["effective_to"] = null;
      }
      return updateOrder({
        patientId: patient_id,
        id,
        ...unflatten(toUpdate),
        scopePrefix,
      });
    },
    "save_order",
    "save_order_success",
    "save_order_error"
  )
);

export const removeOrder = createAsyncThunk(
  "orderDetails/delete",
  withNotifications(
    deleteOrder,
    "delete_order",
    "delete_order_success",
    "delete_order_error"
  )
);

export const sendTromMail = createAsyncThunk(
  "orderDetails/sendTromMail",
  withNotifications(
    startTrom,
    "send_trom",
    "send_trom_success",
    "send_trom_error"
  )
);

const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState: initialState,
  reducers: {
    setActiveOrder(state, action) {
      const { record } = action.payload;
      state.activeOrder = record;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
      //state.changedItems.values["client_id"] = state.activeOrder.client_id;
      //state.changedItems.changed = state.changedItems.changed.filter(c => c !== "client_id");
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.changedItems = {
        changed: [],
        values: [],
      };
      state.activeOrder = {};
    },
  },
  extraReducers: {
    [loadOrder.fulfilled]: (state, action) => {
      state.activeOrder = action.payload;
      state.selectedCategory = action.payload.client.category_id;
      state.selectedClient = action.payload.client;
    },
    [loadOrder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeOrder = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
    [commitChanges.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [removeOrder.fulfilled]: (state, action) => {
      state.activeOrder = {};
    },
    [removeOrder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [loadClient.fulfilled]: (state, { payload: { error, data } }) => {
      state.clientsByID[data.id] = data;
      if (
        data.service_types.find((s) => s.id === state.selectedServiceType) !=
        null
      ) {
        state.selectedServiceType = null;
        state.error = error;
      }
    },
  },
});

export const {
  reset,
  setActiveOrder,
  setSelectedCategory,
  setChangedFormItems,
} = orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;

export const updateChangedFormItems =
  ({ changed, values }) =>
  (dispatch, getState) => {
    const state = getState().orderDetails;
    dispatch(setChangedFormItems({ changed, values }));
    if (state.selectedCategory !== values["client.category_id"]) {
      dispatch(setSelectedCategory(values["client.category_id"]));
    }
    const clientID = state.changedItems.values["client_id"];
    if (values["client_id"] != null && clientID !== values["client_id"]) {
      dispatch(loadClient(values["client_id"]));
    }
  };
