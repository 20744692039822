import user from "../../features/user/UserSlice";
import registration from "../../features/registration/registrationSlice";
import users from "../../features/users/usersSlice";
import userDetails from "../../features/userDetails/userDetailsSlice";
import branches from "../../features/branches/branchesSlice";
import branchDetails from "../../features/branchDetails/branchDetailsSlice";
import clients from "../../features/clients/ClientsSlice";
import clientDetails from "../../features/clientDetails/clientDetailsSlice";
import employees from "../../features/employees/EmployeesSlice";
import staff from "../../features/staff/staffSlice";
import employeeDetails from "../../features/employeeDetails/employeeDetailsSlice";
import employeeAbsences from "../../features/employeeAbsences/employeeAbsencesSlice";
import employeePersonalFile from "../../features/employeePersonalFile/employeePersonalFileSlice";
import employeeSubEmployees from "../../features/employeeSubEmployees/employeeSubEmployeesSlice";
import employeeSubPatients from "../../features/employeeSubPatients/employeeSubPatientsSlice";
import patients from "../../features/patients/PatientsSlice";
import patientDetails from "../../features/patientDetails/patientDetailsSlice";
import patientAbsences from "../../features/patientAbsences/patientAbsencesSlice";
import patientReferences from "../../features/patientReferences/patientReferencesSlice";
import patientHospitalizations from "../../features/patientHospitalizations/patientHospitalizationsSlice";
import patientPersonalFile from "../../features/patientPersonalFile/patientPersonalFileSlice";
import orders from "../../features/orders/OrdersSlice";
import orderDetails from "../../features/orderDetails/OrderDetailsSlice";
import houseVisit from "../../features/houseVisit/houseVisitSlice";
import houseVisits from "../../features/houseVisits/houseVisitsSlice";
import employeeHouseVisits from "../../features/employeeHouseVisits/employeeHouseVisitsSlice";
import workDiaries from "../../features/workDiaries/workDiariesSlice";
import scheduling from "../../features/scheduling/schedulingSlice";
import employeeScheduling from "../../features/employeeScheduling/schedulingSlice";
import employeeWorkDiaries from "../../features/employeeWorkDiaries/employeeWorkDiariesSlice";
import notifications from "../../features/notifications/notificationsSlice";
import sources from "../../features/sources/sourcesSlice";
import pendingReducer from "../../features/pending_actions";
import plan from "../../features/plan/PlanSlice";
import roles from "../../features/roles/rolesSlice";
import roleDetails from "../../features/roleDetails/roleDetailsSlice";
import dashboards from "../../features/dashboard/dashboardSlice";
import gaps from "../../features/gaps/gapsSlice";
import tasks from "../../features/tasks/tasksSlice";
import salary from "../../features/salary/salarySlice";
import prospectus from "../../features/prospectus/prospectusSlice";
import prospectuses from "../../features/prospectuses/prospectusesSlice";
import paymentRequests from "../../features/paymentRequests/paymentRequestsSlice";
import tariff from "../../features/tariff/tariffSlice";
import tariffs from "../../features/tariffs/tariffsSlice";
import clientsTariff from "../../features/clientsTariff/tariffSlice";
import clientsTariffs from "../../features/clientsTariffs/tariffsSlice";
import contract from "../../features/contract/contractSlice";
import contracts from "../../features/contracts/contractsSlice";
import settings from "../../features/settings/settingsSlice";
import organization from "../../features/organization/organizationSlice";
import reports from "../../features/reports/reportsSlice";
import report from "../../features/report/reportSlice";
import assets from "../../features/assets/assetsSlice";
import asset from "../../features/asset/assetSlice";
import ok2Go from "../../features/ok2Go/ok2GoSlice";
import tenantUsers from "../../features/tenantUsers/tenantUsersSlice";
import tenantUser from "../../features/tenantUser/tenantUserSlice";
import tenants from "../../features/tenants/tenantsSlice";
import tenant from "../../features/tenant/tenantSlice";

import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  registration: registration,
  user: user,
  users: users,
  userDetails: userDetails,
  branches: branches,
  branchDetails: branchDetails,
  clients: clients,
  clientDetails: clientDetails,
  employees: employees,
  staff: staff,
  employeeDetails: employeeDetails,
  employeeAbsences: employeeAbsences,
  employeeSubEmployees:employeeSubEmployees,
  employeeSubPatients:employeeSubPatients,
  employeePersonalFile: employeePersonalFile,
  patients: patients,
  patientDetails: patientDetails,
  patientAbsences: patientAbsences,
  patientReferences: patientReferences,
  patientHospitalization: patientHospitalizations,
  patientPersonalFile: patientPersonalFile,
  orders: orders,
  orderDetails: orderDetails,
  houseVisit: houseVisit,
  houseVisits: houseVisits,
  employeeHouseVisits: employeeHouseVisits,
  workDiaries: workDiaries,
  employeeWorkDiaries: employeeWorkDiaries,
  scheduling: scheduling,
  employeeScheduling: employeeScheduling,
  notifications,
  pendingActions: pendingReducer,
  plan: plan,
  gaps: gaps,
  tasks: tasks,
  salary: salary,
  prospectus: prospectus,
  prospectuses: prospectuses,
  paymentRequests: paymentRequests,
  tariff: tariff,
  tariffs: tariffs,
  clientsTariff: clientsTariff,
  clientsTariffs: clientsTariffs,
  contract: contract,
  contracts: contracts,
  reports: reports,
  report: report,
  assets: assets,
  asset: asset,
  sources: sources,
  roles: roles,
  roleDetails: roleDetails,
  dashboards: dashboards,
  settings: settings,
  organization: organization,
  ok2Go: ok2Go,
  tenantUsers: tenantUsers,
  tenantUser: tenantUser,
  tenants: tenants,
  tenant: tenant,
});

export default rootReducer;
