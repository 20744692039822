import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createPatientAbsence,
  updatePatientAbsence,
  getPatientAbsences,
  deletePatientAbsence,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeAbsence: {},
  absences: [],
};

export const loadAbsences = createAsyncThunk(
  "patientAbsences/load",
  async (patientId, thunkAPI) => {
    const { scopePrefix } = thunkAPI.getState().user;
    return getPatientAbsences({ patientId, scopePrefix }).then(
      ({ error, data }) => {
        if (error) {
          thunkAPI.dispatch(
            notifyError({ title: "Get Absence", message: error })
          );
        }
        return data;
      }
    );
  }
);

export const createAbsence = createAsyncThunk(
  "patientAbsences/create",
  withNotifications(
    createPatientAbsence,
    "create_patient_absence",
    "create_patient_absence_success",
    "create_patient_absence_error"
  )
);

export const updateAbsence = createAsyncThunk(
  "patientAbsences/update",
  withNotifications(
    updatePatientAbsence,
    "update_patient_absence",
    "update_patient_absence_success",
    "update_patient_absence_error"
  )
);

export const deleteAbsence = createAsyncThunk(
  "patientAbsences/delete",
  withNotifications(
    deletePatientAbsence,
    "delete_patient_absence",
    "delete_patient_absence_success",
    "delete_patient_absence_error"
  )
);

const patientAbsencesSlice = createSlice({
  name: "patientAbsences",
  initialState: initialState,
  extraReducers: {
    [loadAbsences.fulfilled]: (state, action) => {
      state.absences = action.payload.items;
    },
    [loadAbsences.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export default patientAbsencesSlice.reducer;
