import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPermissions, getRoles } from "../../api/server";

const initialState = { roles: [], permissions: [] };

export const loadPermissions = createAsyncThunk(
  "permissions/load",
  async (id, thunkAPI) => {
    return getPermissions().then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

export const loadRoles = createAsyncThunk(
  "roles/load",
  async (id, thunkAPI) => {
    return getRoles().then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load client", error);
      }
      return data;
    });
  }
);

const roles = createSlice({
  name: "roles",
  initialState: initialState,
  extraReducers: {
    [loadRoles.fulfilled]: (state, action) => {
      state.roles = action.payload.items;
    },
    [loadRoles.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [loadPermissions.fulfilled]: (state, action) => {
      state.permissions = action.payload.items;
    },
    [loadPermissions.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default roles.reducer;
