import antdEn from "antd/locale/en_US";
import enMessages from "../locales/en_US.json";

const EnLang = {
  messages: {
    ...enMessages,
  },
  antd: antdEn,
  locale: "en-US",
  direction: "ltr",
};
export default EnLang;
